<template>
<router-view />
</template>

<script>
export default {
  name: "Offers"
}
</script>

<style scoped>

</style>